import { useEffect } from 'react'
import Link from 'next/link'
import { signIn } from 'next-auth/react'
import { Trans, useTranslation } from 'next-i18next'
import nextI18next from 'next-i18next.config'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import CMSLayout from '@cms/components/Layout/CMSLayout'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { useSiteStructureLoader } from '@cms/hooks/useSiteStructureLoader'
import { useUserContent } from '@cms/hooks/useUserContent'
import { getBreadcrumbLinks } from '@cms/utils/breadcrumb'
import type { CMSPageProps } from '@cms/utils/types'
import { hasMatchWithTags } from '@cms/utils/utils'
import Page from '@components/Layout/Page'
import { default as i18nConfig } from '@config/i18n'
import { ErrorVisual } from '@knauf-group/ct-designs/components/core/ErrorVisual'
import { WebLink } from '@knauf-group/ct-designs/components/core/WebLink'
import type { ReferenceWeb } from '@knauf-group/ct-designs/utils/types'
import { getAppConfigProps } from '@knauf-group/ct-shared-nextjs/client'
import { Contentful } from '@knauf-group/ct-shared-nextjs/web/contentful'
import type { PageTypeFields } from '@knauf-group/ct-shared-nextjs/web/contentful/contentful.types'
import { useScrollEvent } from '@knauf-group/ct-shared-nextjs/web/hooks/useWebAnalytics'
import { sharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web/lib/sharedContentfulProps'
import {
  CONTENT_NESTED_LEVEL,
  CONTENTFUL_TYPES,
} from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import { getSiteStructureWithHash } from '@knauf-group/ct-shared-nextjs/web/utils/sitestructure'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import { Logger } from '@lib/logger'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { FEATURE_SHOW_COOKIE_LIST_TABLE, FEATURE_TAG_KEY } from 'cms/utils/constants'

const CMSPage: React.FC<CMSPageProps> = ({
  locale,
  headerEntries,
  footerEntries,
  cmsPageData,
  siteStructureSerialized,
  breadcrumbLinks,
  optionalPageFeatures,
  isPageInPreviewMode,
  hasUserContent,
  pageId,
  isCommonPage,
  appsConfig,
}) => {
  const {
    content: userContent,
    isError,
    isLoading,
  } = useUserContent({ pageId, isCommonPage, locale, hasUserContent })
  const cmsPageBodyContent = userContent?.data || cmsPageData
  // TODO: first check client-side to see if user claims to have group membership for this page (will double check server-side as well in API route)
  // this can save us an unnecessary API call for good-faith users

  const globalContext = useGlobalContext()
  const cmsPageFields = cmsPageData.fields
  const { t } = useTranslation('cms', { keyPrefix: 'errors' })

  useSiteStructureLoader(siteStructureSerialized, locale)
  useScrollEvent()

  useEffect(() => {
    globalContext.setOptionalPageFeatures(optionalPageFeatures)
  }, [optionalPageFeatures, globalContext])

  const handleError = (error) => {
    if (error?.cause?.status === 400) {
      signIn('okta')
    } else {
      return (
        <ErrorVisual title={t('pageError403Title')} errorType="404">
          <Typography data-cy="protected-content-access-error">
            <Trans
              i18nKey="errors.pageError403Description"
              components={[
                <WebLink
                  key="mailtoLink"
                  reference={{
                    href: 'mailto:app-support@knauf.com',
                    label: '',
                  }}
                  component="a"
                  nextLinkComponent={Link}
                  data-cy="protected-page-access-denied-link-1"
                />,
                <WebLink
                  key="returnToHomeLink"
                  reference={{ href: '/', label: '' }}
                  component="a"
                  nextLinkComponent={Link}
                  data-cy="protected-page-access-denied-link-2"
                />,
              ]}
            />
          </Typography>
        </ErrorVisual>
      )
    }
  }

  const UserContent: React.FC = () => {
    return (
      <>
        {isLoading && (
          <Box
            sx={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {isError ? handleError(isError) : <CMSLayout content={cmsPageBodyContent} />}
      </>
    )
  }

  return (
    <>
      {globalContext.siteStructure && (
        <Page
          headerEntries={headerEntries}
          footerEntries={footerEntries}
          cmsPageFields={cmsPageFields}
          breadcrumbLinks={breadcrumbLinks}
          isPageInPreviewMode={isPageInPreviewMode}
          locale={locale}
          appsConfig={appsConfig}
        >
          {hasUserContent ? <UserContent /> : <CMSLayout content={cmsPageBodyContent} />}
        </Page>
      )}
    </>
  )
}

export const getStaticProps = async ({ locale, preview, params, draftMode = false }) => {
  const { cmsSlug } = params
  const slugFromParams = params?.cmsSlug?.join('/') || ''
  const requestUrl = `/${slugFromParams}`
  const optionalPageFeatures = {}
  let siteStructure
  let headerEntries
  let footerEntries

  // for the scenario where the locale is the default locale, we want to return a 404
  if (locale === i18nConfig.defaultLocale) {
    return {
      notFound: true,
    }
  }

  // dynamic 404 pages should be cached for a short amount of time only
  // otherwise this might lead to inconsistencies for editors when publishing new pages
  const revalidateDynamic404s = 30 // 30 seconds

  try {
    ;({ siteStructure, headerEntries, footerEntries } = await sharedContentfulProps({
      locale,
      preview,
      requireSiteStructure: true,
    }))
  } catch (error) {
    Logger.error('Error fetching shared contentful props', error)
    return {
      notFound: true,
      revalidate: revalidateDynamic404s,
    }
  }

  const { siteStructureSerialized } = getSiteStructureWithHash(siteStructure) || {}

  let pageId
  let isCommonPage
  let isProtectedPage
  let cmsPageData: ContentEntry<PageTypeFields>
  let translations
  let appsConfig

  try {
    // get page id from site structure map
    ;({ pageId, isCommonPage, isProtectedPage } = siteStructure.getPage(requestUrl))
    // fetch full page by page id
    ;[cmsPageData, translations, appsConfig] = await Promise.all([
      Contentful.getPageContentfulEntry<PageTypeFields>({
        entryId: pageId,
        locale,
        isPreview: preview,
        isCommonSpace: isCommonPage,
        include: CONTENT_NESTED_LEVEL,
      }),
      serverSideTranslations(locale, ['cms'], nextI18next),
      getAppConfigProps(),
    ])

    if (!cmsPageData?.fields) {
      return {
        notFound: true,
        revalidate: revalidateDynamic404s,
      }
    }
  } catch (error) {
    Logger.error(error, { locale })
    return {
      notFound: true,
      revalidate: revalidateDynamic404s,
    }
  }

  // TODO: once tags have been added to cookie pages on all CF spaces/environments, remove isCookiePageByUrl
  const cookiePageWebslugs = [
    'cookie-einstellungen',
    'cookie-policy',
    'cookies-policy',
    'politique-de-cookies',
  ]
  const isCookiePageByUrl = cookiePageWebslugs.includes(
    requestUrl.split('/')?.pop()?.toLowerCase(),
  )
  const isCookiePageByTags = hasMatchWithTags(cmsPageData, FEATURE_TAG_KEY, [
    FEATURE_SHOW_COOKIE_LIST_TABLE,
  ])

  if (isCookiePageByUrl || isCookiePageByTags) {
    optionalPageFeatures[FEATURE_SHOW_COOKIE_LIST_TABLE] = true
  }

  // draft mode bypasses the client-side user content rendering
  const hasUserContent = draftMode ? false : isProtectedPage
  const hasContentFormFields = !!cmsPageData?.fields?.sectionBody?.some(
    (section) => section.sys?.contentType?.sys?.id === CONTENTFUL_TYPES.CONTENT_FORM,
  )

  const breadcrumbLinks: ReferenceWeb[] = getBreadcrumbLinks({
    siteStructure,
    currentPageSlug: cmsSlug,
  })

  return {
    props: {
      locale,
      ...translations,
      ...appsConfig,
      headerEntries,
      footerEntries,
      siteStructureSerialized,
      cmsPageData: hasUserContent ? '' : Contentful.scrubCircularRefs(cmsPageData),
      breadcrumbLinks,
      optionalPageFeatures,
      isPageInPreviewMode: draftMode,
      hasUserContent,
      hasContentFormFields,
      pageId,
      isCommonPage,
    },
    revalidate: process.env.REVALIDATE_TIME_IN_SECONDS
      ? parseInt(process.env.REVALIDATE_TIME_IN_SECONDS, 10)
      : 60,
  }
}

export const getStaticPaths = async () => {
  // paths will pre-render at build time.
  // { fallback: 'blocking' } will server-render pages on-demand if the path doesn't exist.
  return { paths: [], fallback: 'blocking' }
}

export default CMSPage
