import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import {
  SiteStructure,
  type SiteStructureMapEntry,
} from '@knauf-group/ct-shared-nextjs/web/siteStructure'
import { Logger } from '@lib/logger'

export const useSiteStructureLoader = (newSiteStructureSerialized: string, locale: string) => {
  const globalContext = useGlobalContext()
  const router = useRouter()

  useEffect(() => {
    const getSiteStructure = async () => {
      try {
        // add the siteStructure to global context
        const newSiteStructure = new SiteStructure()
        newSiteStructure.setMap(
          new Map<string, SiteStructureMapEntry>(JSON.parse(newSiteStructureSerialized)),
          locale,
        )

        // if locale changed or siteStructure changed, update global context which will trigger a re-render
        if (
          globalContext.siteStructure.getLocale() !== newSiteStructure.getLocale() ||
          globalContext.siteStructure.getMap().size !== newSiteStructure.getMap().size
        ) {
          globalContext.setSiteStructure(newSiteStructure)
        }
      } catch (error) {
        Logger.error(error)
      }
    }
    getSiteStructure()
  }, [globalContext, router, newSiteStructureSerialized, locale])
}

export default useSiteStructureLoader
