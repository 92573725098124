import { useSession } from 'next-auth/react'

import useSWR from 'swr'

const fetcher = async (url) => {
  const res = await fetch(url)

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.', {
      cause: { status: res.status },
    })
    // Attach extra info to the error object.
    error.message = await res.json()
    throw error
  }

  return res.json()
}

type UseUserContentProps = {
  pageId: string
  isCommonPage: boolean
  locale: string
  hasUserContent: boolean
}

export const useUserContent = ({
  pageId,
  isCommonPage,
  locale,
  hasUserContent,
}: UseUserContentProps) => {
  const { data: session, status } = useSession()
  const hasActiveUserSession = !!session?.user || status !== 'loading'
  const { data, error, isLoading } = useSWR(
    hasUserContent && hasActiveUserSession
      ? `/api/usercontent?pageId=${pageId}&locale=${locale}&isCommon=${isCommonPage}`
      : null,
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  )

  return {
    content: data,
    isLoading,
    isError: error,
  }
}

export default useUserContent
